<template>
  <div class="timeline-list-block timeline-marker">
    <h3>
      <span> <img :src="model.icon" /> </span> {{ model.title }}
    </h3>
    <ul>
      <li v-for="item in model.items" :key="item.title">
        <div class="image-text-list__img">
          <img
            :src="`//hangr.kmpassociates.co.uk/assets/gla/images/temp/${item.icon}.png`"
          />
        </div>
        <div class="image-text-list__content">
          <h4>{{ item.title }}</h4>
          <div v-html="item.body"></div>
        </div>
      </li>
    </ul>
    <a
      :href="model.link.url"
      v-if="model.link != null && model.link.url.length > 0"
      class="btn btn--secondary"
      >{{ model.link.text }}</a
    >
  </div>
</template>

<script>
var model = {
  items: [
    {
      icon: "laptops",
      title: "LAPTOPS, TABLETS, E-BOOKS & OTHER ELECTRICAL ITEMS",
      body: "<p>Remove them from your bags and place them in separate trays through security</p><p>Large electrical items are those that exceed 20cm</p>",
    },
    {
      icon: "metal-items",
      title: "METAL ITEMS",
      body: "<p>Put all keys, coins, and mobile phones into the tray</p><p>They can be inside your hand luggage, in a coat pocket (on the tray), or in the tray itself - but you can't carry them on your person through the scanners</p>",
    },
    {
      icon: "liquids",
      title: "LIQUIDS",
      body: "<p>All liquids should be in containers of 100ml or less</p><p>Place them in a clear, resealable plastic bag of no more than 1 litre in capacity - you can pick one up at the airport</p><p>Place liquids in a separate tray to your other baggage and belongings</p><p>If you're carrying medicines over 100ml - have your prescription or doctor's letter ready for inspection</p>",
    },
    {
      icon: "clothes",
      title: "CLOTHES",
      body: "<p>Remove belts and place them in the tray</p><p>If asked to remove your shoes, they should also be placed in the tray</p>",
    },
    {
      icon: "laptops",
      title: "LAPTOPS, TABLETS, E-BOOKS & OTHER ELECTRICAL EQUIPMENT",
      body: "<p>Take them from your bags and place them in trays. Large electrical items are those that are approximately A5 in size or where any dimension exceeds 20cm.</p>",
    },
    {
      icon: "metal-items",
      title: "METAL ITEMS",
      body: "<p>Keys, coins and mobile phones can be put either right into the tray, in your hand baggage, or in your coat pockets in the tray.</p>",
    },
    {
      icon: "liquids",
      title: "LIQUIDS, AEROSOLS & GELS",
      body: "<p>They should be in containers of 100ml or less, placed in a single transparent resealable plastic bag that has a capacity of no greater than 1 litre (approximately 20cm x 20cm). You can find free bags at the security area. The bag must be completely closed and placed in your tray separately to your other baggage.If you're carrying liquid medicines in containers over 100ml, have your prescription or doctor's letter ready</p>",
    },
    {
      icon: "clothes",
      title: "CLOTHES",
      body: "<p>Remove any belt and place it in the tray. If we ask you to remove your shoes, they should go in the tray too</p>",
    },
  ],
  icon: "//hangr.kmpassociates.co.uk/assets/snn/images/temp/timeline-icons/demo-icon.png",
  title: "SECURITY CHECKLIST",
  link: {
    url: "/",
    text: "More details about security",
  },
};

export default {
  name: "TimelineSecurityPanel",
  props: [""],
  components: {},
  data() {
    return {
      model,
    };
  },
};
</script>

<style lang="scss" scoped>
.timeline-list-block {
  width: 100%;
  padding: 3rem;
  font-size: 1.8rem;
  line-height: 1.5;
  border: 1px solid $skin-colour-primary;

  .image-text-list__content,
  .image-text-list__img {
    display: table-cell;
    vertical-align: top;
    margin: 0;
  }

  .image-text-list__img {
    width: 5rem;

    @include breakpoint($desktop) {
      width: 6rem;
    }

    img {
      max-width: 3rem;
      display: block;

      @include breakpoint($desktop) {
        margin: 0 auto;
        max-width: 6rem;
      }
    }
  }

  .image-text-list__content div {
    margin-left: -5rem;

    @include breakpoint($desktop) {
      margin-left: 0;
    }
  }

  h3 {
    position: relative;
    align-items: center;
    display: flex;
    font-family: $futura;
    font-weight: 200;

    span {
      display: block;
      width: 4rem;
      height: 4rem;
      margin: 0 1rem 0 0;
      border-radius: 50%;
      background: $skin-colour-primary-bright;
      position: relative;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        display: block;
        max-width: 2.5rem;
        max-height: 2.5rem;
      }
    }
  }
  .btn {
    margin-top: 3rem;
  }
}
</style>
