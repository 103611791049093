<template>
  <div class="timeline-panel-tabs timeline-marker">
    <ul class="resp-tabs-list">
      <li
        v-for="(item, index) in items"
        :key="index"
        :class="['resp-tab-item', { 'resp-tab-active': index === activeIndex }]"
        :aria-controls="'tab_item-' + index"
        role="tab"
        @click="activeIndex = index"
      >
        <i class="icon"><SvgIcon :name="item.icon" /></i>{{ item.title }}
      </li>
    </ul>

    <div class="resp-tabs-container">
      <template v-for="(item, index) in items" :key="index">
        <h2
          class="resp-accordion"
          :class="{ 'resp-accordion-active': index === activeIndex }"
          :aria-labelledby="'tab_item-' + index"
          role="tab"
          @click="activeIndex = activeIndex === index ? -1 : index"
        >
          <span class="resp-arrow" />
          <i class="icon"><SvgIcon :name="item.icon" /></i>{{ item.title }}
        </h2>

        <div
          class="resp-tab-content"
          :class="{ 'resp-tab-content-active': index === activeIndex }"
          :aria-labelledby="'tab_item-' + index"
        >
          <div v-html="item.body"></div>
          <a
            :href="item.link.url"
            v-if="item.link != null && item.link.url.length > 0"
            class="btn btn--primary"
            >{{ item.link.text }}</a
          >
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/Icon";

var model = {
  items: [
    {
      icon: "car",
      title: "By Car",
      body: "<p>Shannon Airport is the only airport with direct highway access, making it extremely easy and simple to get to. We advise beginning your journey with plenty of time to spare, whether you're driving, using public transport, or using a local cab company. This will give you the peace of mind to know that you'll be checking in on time so you can begin your journey stress-free</p>",
      link: {
        url: "#",
        text: "Get directions",
      },
    },
    {
      icon: "train",
      title: "By Train",
      body: `<iframe src="https://www.thetrainline.com/home/search?phId=1101l45zU&amp;formBgColor=213368&amp;buttonBgColor=b01f63" allowfullscreen=""></iframe>`,
    },
    {
      icon: "bus",
      title: "By Bus",
      body: "<p>Shannon Airport is the only airport with direct highway access, making it extremely easy and simple to get to. We advise beginning your journey with plenty of time to spare, whether you're driving, using public transport, or using a local cab company. This will give you the peace of mind to know that you'll be checking in on time so you can begin your journey stress-free</p><p>Shannon Airport is the only airport with direct highway access, making it extremely easy and simple to get to. We advise beginning your journey with plenty of time to spare, whether you're driving, using public transport, or using a local cab company. This will give you the peace of mind to know that you'll be checking in on time so you can begin your journey stress-free</p>",
      link: {
        url: "#",
        text: "Get directions",
      },
    },
  ],
};

export default {
  name: "TimelineTabbedPanel",
  props: [""],
  components: { SvgIcon },
  data() {
    return {
      items: model.items,
      activeIndex: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.timeline-panel-tabs {
  width: 100%;
  background-color: $skin-colour-primary;
  color: $white;
  margin-bottom: 5rem;

  .resp-tabs-list {
    display: table;
    width: 100%;
    margin: 0 !important;
    display: none;

    @include breakpoint($desktop-sml) {
      display: table !important;
    }

    li {
      display: table-cell !important;
      vertical-align: middle;
      cursor: pointer;
      text-align: center;
      padding: 8px 15px;
      line-height: 1.5;
      font-size: 1.7rem !important;
      background: $white;
      color: $skin-colour-primary !important;
      border: 1px solid $skin-colour-primary;
      border-bottom: 1px solid $skin-colour-primary;

      &:last-child {
        border-left: 0;
      }

      &:first-child {
        border-right: 0;
      }

      &.resp-tab-active {
        color: $white !important;
        border-color: $skin-colour-primary;
        background: $skin-colour-primary;

        i {
          color: $skin-colour-primary-bright;
        }
      }
    }
  }

  .resp-tab-content {
    padding: 3rem;
    display: none;

    &.resp-tab-content-active {
      display: block;
    }
  }

  .icon-arrow {
    position: absolute;
    right: 10px;
    top: 17px;
  }

  .resp-accordion {
    display: block;
    position: relative;
    font-family: $futura;
    font-size: 2.2rem;
    font-weight: 100;
    line-height: 1.3;
    margin: 0px;
    padding: 10px 15px;
    background: $white;
    color: $skin-colour-primary;
    border: 1px solid $skin-colour-primary;
    border-top: 0px solid $skin-colour-primary;

    &:first-child {
      border-top: 1px solid $skin-colour-primary;
    }

    @include breakpoint($desktop-sml) {
      display: none;
    }

    &.resp-accordion-active {
      color: $white;
      border-top: none;
      border-bottom: 1px solid $white;
      background: $skin-colour-primary;

      i {
        color: $skin-colour-primary-bright;
      }
    }
  }

  .resp-arrow {
    width: 0;
    height: 0;
    float: right;
    margin-top: 12px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 12px solid $skin-colour-primary;
  }

  .resp-accordion-active .resp-arrow {
    border: none;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 12px solid $white;
  }
}
</style>
