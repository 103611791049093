<template>
  <div class="timeline-panel-image timeline-marker">
    <div
      class="timeline-panel__img"
      style="
        background-image: url('https://hangr.kmpassociates.co.uk/assets/snn/images/temp/timeline-destination.jpg');
      "
    ></div>
    <div class="timeline-panel__copy">
      <h3>
        <span><img :src="model.icon" /></span>{{ model.title }}
      </h3>
      <table>
        <tbody>
          <tr>
            <td>New York</td>
            <td></td>
          </tr>
          <tr>
            <td>Weather</td>
            <td>32° - Sunny</td>
          </tr>
        </tbody>
      </table>
      <a
        :href="model.link.url"
        v-if="model.link != null && model.link.url.length > 0"
        class="btn btn--primary"
        target="_blank"
        >{{ model.link.text }}</a
      >
    </div>
  </div>
</template>

<script>
var model = {
  icon: "//hangr.kmpassociates.co.uk/assets/gla/images/temp/timeline-icons/destination-guide.png",
  title: "Destination Guide",
  body: "",
  link: {
    url: "/",
    text: "Read more",
  },
};

export default {
  name: "TimelineImage",
  props: [""],
  components: {},
  data() {
    return {
      model,
    };
  },
};
</script>

<style lang="scss" scoped>
.timeline-panel-image {
  width: 100%;
  background-color: $skin-colour-primary;
  color: $white;
  margin-bottom: 5rem;

  h3 {
    position: relative;
    display: flex;
    align-items: center;
    color: $white !important;
    font-family: $futura;
    font-weight: 200;

    span {
      position: relative;
      display: block;
      width: 4rem;
      height: 4rem;
      margin: 0 1rem 0 0;
      border-radius: 50%;
      background: $white;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        display: block;
        max-width: 2.5rem;
        max-height: 2.5rem;
      }
    }
  }

  .timeline-panel__copy {
    padding: 3rem;
  }

  .timeline-panel__img {
    height: 28rem;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
  }

  table {
    background: transparent;
    border-collapse: collapse;
    max-width: 50rem;

    tr:first-of-type {
      border-top: 1px solid $white;
    }

    td {
      font-size: 1.8rem;
      text-align: left;
      line-height: 1.2;
      vertical-align: top;
      padding: 10px 0;
      border-bottom: 1px solid $white;
    }
  }
}
</style>
