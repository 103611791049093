<template>
  <div class="grid timeline-marker">
    <div class="grid__half">
      <div class="timeline-panel-image-block">
        <div class="timeline-panel__offer">An American Classic</div>
        <div
          class="timeline-panel__img"
          style="
            background-image: url('http://hangr.kmpassociates.co.uk/assets/tpa/images/temp/hard-rock-cafe.jpg');
          "
        ></div>
        <div class="timeline-panel__copy">
          <h3>Hard Rock Cafe</h3>
          <a
            :href="model.link.url"
            v-if="model.link != null && model.link.url.length > 0"
            class="btn btn--primary"
            >{{ model.link.text }}</a
          >
        </div>
      </div>
    </div>

    <div class="grid__half">
      <div class="timeline-panel-image-block">
        <div class="timeline-panel__offer">Asian &amp; Sushi</div>
        <div
          class="timeline-panel__img"
          style="
            background-image: url('http://hangr.kmpassociates.co.uk/assets/tpa/images/temp/pf-changs.jpg');
          "
        ></div>
        <div class="timeline-panel__copy">
          <h3>P.F. Chang's</h3>
          <a
            :href="model.link.url"
            v-if="model.link != null && model.link.url.length > 0"
            class="btn btn--primary"
            >{{ model.link.text }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var model = {
  icon: "",
  title: "",
  body: "",
  link: {
    url: "/",
    text: "Find latest deals",
  },
};

export default {
  name: "TimelinePanelBasic",
  props: [""],
  components: {},
  data() {
    return {
      model,
    };
  },
};
</script>

<style lang="scss" scoped>
.grid__half {
  position: relative;

  &:first-child {
    margin-bottom: 5rem;

    @include breakpoint($tablet) {
      margin-bottom: 0;
    }
  }
}

.timeline-panel-image-block {
  background: $skin-colour-primary;
  color: $white;

  h3 {
    position: relative;
    color: #ffffff !important;

    font-size: 1.6rem !important;
    margin-bottom: 3rem;
    font-family: $futura;
    font-weight: 400;
  }

  .timeline-panel__img {
    height: 18rem;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
  }

  .timeline-panel__offer {
    position: absolute;
    left: -5px;
    top: -5px;
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
    color: #121212;
    background: $white;
    padding: 5px 10px;
    letter-spacing: 0;
    border: 3px solid $skin-colour-primary-bright;
  }

  .timeline-panel__copy {
    padding: 3rem;

    .btn {
      width: 100%;
      min-width: 0;
      margin-top: 4rem;
    }
  }
}
</style>
