<template>
  <div class="timeline-security-panel timeline-marker">
    <h3>
      <span> <img :src="model.icon" /> </span> {{ model.title }}
    </h3>
    <div v-html="model.body"></div>
    <a
      :href="model.link.url"
      v-if="model.link != null && model.link.url.length > 0"
      class="btn btn--primary"
      >{{ model.link.text }}</a
    >
  </div>
</template>

<script>
var model = {
  icon: "//hangr.kmpassociates.co.uk/assets/snn/images/temp/timeline-icons/demo-icon.png",
  title: "Book Fast Track",
  body: "Book now and save time with a fast track security",
  link: {
    url: "/",
    text: "Book from €4.00",
  },
};

export default {
  name: "TimelineSecurityPanel",
  props: [""],
  components: {},
  data() {
    return {
      model,
    };
  },
};
</script>

<style lang="scss" scoped>
.timeline-security-panel {
  width: 100%;
  padding: 3rem;
  background-color: $skin-colour-primary;
  color: $white;
  font-size: 1.8rem;
  line-height: 1.5;

  h3 {
    color: $white !important;
    position: relative;
    align-items: center;
    display: flex;
    font-family: $futura;
    font-weight: 200;

    span {
      display: block;
      width: 4rem;
      height: 4rem;
      margin: 0 1rem 0 0;
      border-radius: 50%;
      background: $skin-colour-primary-bright;
      position: relative;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        display: block;
        max-width: 2.5rem;
        max-height: 2.5rem;
      }
    }
  }

  .btn {
    margin-top: 3rem;
  }
}
</style>
